<template>
  <v-form>
    <v-row dense>
      <v-col cols="6">
        <v-text-field outlined dense label="Group Name" v-model="record.GroupName"></v-text-field>
      </v-col>

      <v-col cols="6">
        <v-autocomplete
          outlined 
          dense
          v-model="record.PriceList"
          :items="priceList"
          item-text="ListName"
          item-value="id"
          label="Price List"
        ></v-autocomplete>
      </v-col>

      <v-col cols="6">
        <v-autocomplete
          outlined 
          dense
          v-model="record.DiscRel"
          :items="discounts"
          item-text="name"
          item-value="value"
          label="Effective Discount"
        ></v-autocomplete>
      </v-col>

      <v-col cols="6">
        <v-autocomplete
          outlined 
          dense
          v-model="record.EffecPrice"
          :items="prices"
          item-text="name"
          item-value="value"
          label="Effective Price"
        ></v-autocomplete>
      </v-col>

      <v-col cols="12">
        <v-btn color="accent" @click="sendData" :loading="loader">
          <v-icon left>mdi-content-save</v-icon>Save
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>


<script>
export default {
  props: {
    initial: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    record: {},
    loader: false,
    priceList: [],
    discounts: [
      { name: 'Lowest Discount', value: 'L' },
      { name: 'Highest Discount', value: 'H' },
      { name: 'Average', value: 'A' },
      { name: 'Total', value: 'S' },
      { name: 'Discount Multiples', value: 'M' }
    ],
    prices: [
      { name: 'Default Priority', value: 'D' },
      { name: 'Lowest Price', value: 'L' },
      { name: 'Highest Price', value: 'H' }
    ]
  }),
  watch: {
    initial: {
      handler: "init",
      immediate: true
    }
  },
  methods: {
    init(val) {
      this.record = { ...val };
    },
    setDate(date) {
      this.record.date = date;
    },
    sendData() {
      this.loader = true;
      this.$emit("data", this.record);
    },
    getPriceList() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/price_lists`)
        .then(res => {
          self.priceList = res.ResponseData;
          self.loading = false;
        })
        .catch(err => {
          this.$refs.snackbar.show(err, "red");
        });
    }
  },
  created() {
    this.getPriceList();
  }
};
</script>